import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';
import DownloadsLinks from '../../../small_views/downloads_links/downloads_links';
import DefaultActionsButtons from '../../../small_views/interactive_view_section/default_actions_buttons/default_actions_buttons';

import data from './viti_interaction_desktop_data';

import classes from './viti_interaction_desktop_section.module.css';

const AgriInteractiveDesktopSection = () => {
	return (
		<InteractiveViewSection
			reverseContent
			viewType="desktop"
			sectionClassName={classes.section}
			titleClassName={classes.title}
			title={<Title />}
			{...{ data }}
		>
			<DefaultActionsButtons />
		</InteractiveViewSection>
	)
};

const Title = () => {
	const title = 'Toujours plus de fonctionnalités';
	const displayDownloadsLinksUnderPhoneSection = useMediaQuery('(max-width: 1300px)', { defaultMatches: true });
	if (displayDownloadsLinksUnderPhoneSection) {
		return title;
	}
	return (
		<div className={classes.titleDownloadsLinksContainer}>
			{title}
			<DownloadsLinks className={classes.downloadsLinks} />
		</div>
	);
}

export default AgriInteractiveDesktopSection;
