import React from 'react';

import HelmetLayout from '../../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../../components/layouts/with_gradient_layout/with_gradient_layout';

import VitiHeader from '../../components/solutions/viti/viti_header/viti_header';
import VitiInteractivePhoneSection from '../../components/solutions/viti/viti_interactive_phone_section/viti_interactive_phone_section';
import VitiInteractiveDesktopSection from '../../components/solutions/viti/viti_interactive_desktop_section/viti_interactive_desktop_section';
import VitiTestimonialsSection from '../../components/solutions/viti/viti_testimonials_section/viti_testimonials_section';

const Viti = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account']
			}}
		>
			<VitiHeader />
			<WithGradientLayout>
				<VitiInteractivePhoneSection />
				<VitiInteractiveDesktopSection />
			</WithGradientLayout>
			<VitiTestimonialsSection />
		</MainLayout>
	</HelmetLayout>
);

export default Viti;
