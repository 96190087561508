import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';

import data from './viti_interaction_phone_data';

import classes from './viti_interaction_phone_section.module.css';

const VitiInteractivePhoneSection = () => {
	const displayDownloadsLinks = useMediaQuery('(max-width: 1300px)', { defaultMatches: true });
	return (
		<InteractiveViewSection
			titleClassName={classes.title}
			title="Des fonctionnalités pensées pour et par des viticulteurs"
			viewType="phone"
			showDownloadsLinks={displayDownloadsLinks}
			viewDeviceProps={{
				className: classes.viewDevice
			}}
			{...{ data }}
		/>
	);
}

export default VitiInteractivePhoneSection;
