import React, { useCallback } from 'react';

import { animated, config, useSpring } from 'react-spring';

import Header from '../../../small_views/header/header';
import Tag from '../../../design_system/tag/tag';
import PlayVideo from '../../../small_views/play_video/play_video';

import Background from '../../../../assets/images/pages/solutions/viti/header/background.jpg';
import BackgroundLowDef from '../../../../assets/images/pages/solutions/viti/header/background_low_def.jpg';

import classes from './viti_header.module.css';

const VitiHeader = () => {
	const interpolation = useCallback(value => `translate3d(0, ${value + 100}px, 0)`);
	return (
		<Header
			className={classes.container}
			titleClassName={classes.title}
			backgroundClassName={classes.background}
			title={(
				<div className={classes.titleTagContainer}>
					<AnimatedTag />
					<span>
						{'Fini les parcelles oubliées ou les rangs manqués.'}
					</span>
				</div>
			)}
			background={Background}
			backgroundLowDef={BackgroundLowDef}
			parallaxLayerProps={{
				customInterpolation: interpolation
			}}
		>
			<PlayVideo
				className={classes.playVideo}
				pulseColor="light"
				changeTextColorOnHover="primary"
			/>
		</Header>
	);
}

const AnimatedTag = () => {
	const { opacity } = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		delay: 1000,
		config: config.slow
	});
	return (
		<Tag
			className={classes.tag}
			component={animated.span}
			style={{ opacity }}
			label="VITI"
		/>
	);
}

export default VitiHeader;
